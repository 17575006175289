import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizComponent } from './containers/quiz/quiz.component';
import { QuizRoutingModule } from './quiz-routing.module';
import { CoreModule } from '../core/core.module';
import { IconModule } from '@teamfoster/sdk/icon';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { EffectsModule } from '@ngrx/effects';
import { MatSliderModule } from '@angular/material/slider';
import { QuizSliderComponent } from './components/quiz-slider/quiz-slider.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { RouterModule } from '@angular/router';
import { EmojiAnimationComponent } from './components/emoji-animation/emoji-animation.component';
import { LottieModule } from 'ngx-lottie';
@NgModule({
  declarations: [QuizComponent, QuizSliderComponent, EmojiAnimationComponent],
  imports: [
    QuizRoutingModule,
    CommonModule,
    CoreModule,
    IconModule,
    CoreModule,
    RouterModule,
    IconModule,
    DictionaryNgrxModule,
    MatSliderModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    LottieModule,
  ],
})
export class QuizModule {}
