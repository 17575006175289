import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Question } from '../../models';
import * as pageActions from '../actions/questions.action';

export interface QuestionsState {
  entities: { [id: number]: Question };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: QuestionsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const questionReducer = createReducer(
  initialState,
  on(pageActions.LoadQuestions, (state: QuestionsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadQuestionsSuccess, (state: QuestionsState, { questions }) => {
    const entities = ToEntities(questions, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadQuestionsFail, (state: QuestionsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getQuestionsEntities = (state: QuestionsState) => state.entities;
export const getQuestionsLoading = (state: QuestionsState) => state.loading;
export const getQuestionsLoaded = (state: QuestionsState) => state.loaded;
