import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent {
  @Input() start!: Date | string;
  @Input() end?: Date | string;
  @Input() startTime?: string;
  @Input() endTime?: string;

  getMonth(d: Date | string) {
    let date = typeof d === 'string' ? new Date(d) : d;
    return date.toLocaleString('nl-NL', { month: 'short' });
  }

  isThisYear(d: Date | string) {
    const today = new Date();
    let date = typeof d === 'string' ? new Date(d) : d;

    return date.getFullYear() === today.getFullYear();
  }
}
