import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() color: 'primary' | 'secondary' = 'secondary';
  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
