import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import * as actions from '../actions/answers.action';

export interface AnswersState {
  answers: { [questionId: number]: number };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: AnswersState = {
  answers: {},
  loaded: false,
  loading: false,
};

export const AnswerReducer = createReducer(
  initialState,
  on(actions.InitAnswers, (state: AnswersState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.InitAnswersSuccess, (state: AnswersState, { answers }) => {
    return {
      ...state,
      answers: { ...answers },
      loading: false,
      loaded: true,
    };
  }),
  on(actions.InitAnswersFail, (state: AnswersState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.SetAnswerSuccess, (state: AnswersState, { answer, questionId }) => {
    const answers = {
      ...state.answers,
      [questionId]: answer,
    };
    return {
      ...state,
      answers,
    };
  })
);

export const getAnswers = (state: AnswersState) => state.answers;
export const getAnswersLoading = (state: AnswersState) => state.loading;
export const getAnswersLoaded = (state: AnswersState) => state.loaded;
