import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuizComponent } from './containers/quiz/quiz.component';
import { QuestionsGuard } from './guards';
import { InitAnswersGuard } from './guards/init-answers.guard';

const routes: Routes = [
  {
    path: '',
    component: QuizComponent,
    canActivate: [QuestionsGuard, InitAnswersGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuizRoutingModule {}
