import { ChangeDetectionStrategy, Component, OnInit, Signal, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetAnswer, getAllOrderedQuestions, getAnswers } from '../../store';
import { Question } from '../../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizComponent implements OnInit {
  private store = inject(Store);
  private router = inject(Router);
  questions$ = this.store.selectSignal(getAllOrderedQuestions);
  answers$ = this.store.selectSignal(getAnswers);

  questionsWithAnswers$: Signal<{ question: Question; answer: number | null }[]> = computed(() => {
    return this.questions$().map(q => ({
      question: q,
      answer: this.answers$()[q.id] || null,
    }));
  });

  activeQuizIndex: number = 0;

  ngOnInit(): void {
    this.activeQuizIndex = +(localStorage.getItem('vvt:quiz-progress') || 0);

    if (this.activeQuizIndex === this.questions$().length) {
      this.activeQuizIndex -= 1;
    }
  }

  nextQuestion() {
    if (this.activeQuizIndex <= this.questions$().length) {
      this.activeQuizIndex += 1;
    }

    // when it is the last question fake loading, then redirect to results
    if (this.activeQuizIndex === this.questions$().length) {
      this.setLoadingState();
    }

    localStorage.setItem('vvt:quiz-progress', JSON.stringify(this.activeQuizIndex));
  }

  setLoadingState() {
    setTimeout(() => {
      this.router.navigate(['/', 'roadmap']);
    }, 2000);
  }

  prevQuestion() {
    if (this.activeQuizIndex > 0) {
      this.activeQuizIndex -= 1;
    }
    localStorage.setItem('vvt:quiz-progress', JSON.stringify(this.activeQuizIndex));
  }

  saveAnwser(answer: number, questionId: number) {
    this.store.dispatch(SetAnswer({ questionId, answer }));
    this.nextQuestion();
  }

  getDistanceFromIndex(index: number) {
    return index - this.activeQuizIndex;
  }

  trackByFn(index: number, q: { question: Question; answer: number | null }) {
    return q.question.id;
  }
}
