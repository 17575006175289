import { createAction, props } from '@ngrx/store';
import { Question } from '../../models';

export const SetAnswer = createAction('[Quiz] Set Answer', props<{ questionId: number; answer: number }>());
export const SetAnswerSuccess = createAction('[Quiz] Set Answer Success', props<{ questionId: number; answer: number }>());
export const SetAnswerFail = createAction('[Quiz] Set Answer Fail', props<{ error: any }>());

export const InitAnswers = createAction('[Quiz] Init Answers');
export const InitAnswersSuccess = createAction('[Quiz] Init Answers Success', props<{ answers: { [questionId: number]: number } }>());
export const InitAnswersFail = createAction('[Quiz] Init Answers Fail', props<{ error: any }>());
