import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAnswers from '../reducers/answers.reducer';

export const getAnswersState = createSelector(fromFeature.getQuizState, (state: fromFeature.QuizState) => state.answers);

export const getAnswers = createSelector(getAnswersState, fromAnswers.getAnswers);
export const getAnswersLoaded = createSelector(getAnswersState, fromAnswers.getAnswersLoaded);
export const getAnswersLoading = createSelector(getAnswersState, fromAnswers.getAnswersLoading);
