import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { CanActivateFn } from '@angular/router';

export const InitAnswersGuard: CanActivateFn = route => {
  const store = inject(Store);
  return store
    .select(fromStore.getAnswersLoaded)
    .pipe(
      tap(loaded => {
        if (!loaded) {
          store.dispatch(fromStore.InitAnswers());
        }
      }),
      filter(loaded => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
