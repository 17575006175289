import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'white' = 'white';
  @Input() hasRust: boolean = true;
}
