import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-quiz-slider',
  templateUrl: './quiz-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizSliderComponent implements OnChanges {
  @Output() updatedValue = new EventEmitter<number>();
  @Input() value: number | null = 5;

  cd = inject(ChangeDetectorRef);
  sliderValue = 5;

  max = 10;
  thumbPosition = '50%'; // for custom label positioning

  //For calculating speed
  prevSliderValue = 5;
  prevTimestamp = 0;
  velocity = 0;

  inputDirty = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && this.value !== null) {
      this.thumbPosition = `${(this.value / this.max) * 100}%`;
      this.sliderValue = this.value;
      this.inputDirty = true;
      this.cd.detectChanges();
    }
  }

  formatSliderValue(value: number) {
    return value.toString();
  }

  onSliderChange(value: string) {
    this.sliderValue = +value;
    this.updateSpeedStats(this.sliderValue);
    this.thumbPosition = `${(this.sliderValue / this.max) * 100}%`;
  }

  updateSpeedStats(value: number) {
    const currentTimestamp = Date.now();
    const elapsedTime = currentTimestamp - this.prevTimestamp;
    const valueChange = value - this.prevSliderValue;

    if (elapsedTime > 0) {
      this.velocity = valueChange / elapsedTime; // Calculate velocity (change in value per millisecond)
    }

    this.prevSliderValue = value;
    this.prevTimestamp = currentTimestamp;
  }

  mouseUp() {
    this.velocity = 0;
    this.inputDirty = true;
    this.cd.detectChanges();
  }
}
