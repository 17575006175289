import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/questions.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { QuestionsService } from '../../services';

export const LoadQuestions$ = createEffect(
  (actions$ = inject(Actions), service = inject(QuestionsService)) => {
    return actions$.pipe(
      ofType(actions.LoadQuestions),
      switchMap(() =>
        service.getQuestions().pipe(
          map(questions => actions.LoadQuestionsSuccess({ questions })),
          catchError(error => of(actions.LoadQuestionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
