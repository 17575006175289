import { PLATFORM_ID, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, zip, tap, mergeMap } from 'rxjs';

import * as actions from '../actions/answers.action';
import { isPlatformBrowser } from '@angular/common';

export const InitAnswers$ = createEffect(
  (actions$ = inject(Actions), platformId = inject(PLATFORM_ID)) => {
    return actions$.pipe(
      ofType(actions.InitAnswers),
      map(() => {
        let answers: { [questionId: number]: number } = {};
        if (isPlatformBrowser(platformId)) {
          answers = JSON.parse(localStorage.getItem('vvt:quiz-answers') || '{}');
        }
        return actions.InitAnswersSuccess({ answers });
      })
    );
  },
  { functional: true }
);

export const setAnswer$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.SetAnswer),
      map(({ answer, questionId }) => {
        let answers: { [questionId: number]: number } = JSON.parse(localStorage.getItem('vvt:quiz-answers') || '{}');
        answers = {
          ...answers,
          [questionId]: answer,
        };

        localStorage.setItem('vvt:quiz-answers', JSON.stringify(answers));
        return actions.SetAnswerSuccess({ questionId, answer });
      })
    );
  },
  { functional: true }
);
