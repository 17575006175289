import * as fromQuestions from '../reducers/questions.reducer';
import * as fromAnswers from '../reducers/answers.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'quiz';

export interface QuizState {
  questions: fromQuestions.QuestionsState;
  answers: fromAnswers.AnswersState;
}

export const reducers: ActionReducerMap<QuizState> = {
  questions: fromQuestions.questionReducer,
  answers: fromAnswers.AnswerReducer,
};

export const getQuizState = createFeatureSelector<QuizState>(featureKey);

export const metaReducers: MetaReducer<QuizState>[] = !environment.production ? [] : [];
