<!-- Mijn poging, groetjes Laetitia -->
<!-- Start datum -->

<app-card [color]="'tertiary'" [hasRust]="true" class="date-card">
  <div class="event-date type-body-lg date__start">
    <span class="event-date__day">{{ start | date : 'dd' }}</span>
    <span class="event-date__month">{{ getMonth(start) }}</span>
    <span class="event-date__year type-body-xs" *ngIf="!isThisYear(start)">{{ start | date : 'y' }}</span>
  </div>

  <ng-container *ngIf="end && (end | date : 'dd') !== (start | date : 'dd')">
    <span class="event-date--end__span type-body-xxs mb-2 date__tot">tot</span>
    <!-- Eind datum -->
    <div class="event-date event-date--end my-2 type-body-lg date__end">
      <span class="event-date__day">{{ end | date : 'dd' }}</span>
      <span class="event-date__month">{{ getMonth(end) }}</span>
      <span class="event-date__year type-body-xs" *ngIf="!isThisYear(end)">{{ end | date : 'y' }}</span>
    </div>
  </ng-container>

  <!-- De tijd -->
  <div class="event-date event-date--end__label mt-2 type-body-xxs date__time" *ngIf="startTime || endTime">
    <span *ngIf="startTime" class="d-inline-block event-date--end__label">
      {{ startTime }}
    </span>
    <ng-container *ngIf="endTime">-&nbsp;</ng-container>
    <span class="event-date--end__label" *ngIf="endTime">{{ endTime }}</span>
  </div>
</app-card>
