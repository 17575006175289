<div class="quiz-slider">
  <div class="quiz-slider__custom-label" [style.--slider-speed]="velocity" [ngStyle]="{ left: thumbPosition }">
    <app-emoji-animation [value]="sliderValue"></app-emoji-animation>
  </div>

  <mat-slider
    class="quiz-slider__slider"
    min="0"
    [max]="max"
    value="2"
    step="1"
    showTickMarks
    discrete="false"
    [displayWith]="formatSliderValue"
    (input)="onSliderChange(slider.value)"
    (pointerup)="mouseUp()"
  >
    <input matSliderThumb #slider [value]="sliderValue" />
  </mat-slider>

  <footer class="mt-auto mb-0 pt-4">
    <button *ngIf="inputDirty" class="button button--secondary card__action w-100" (click)="updatedValue.emit(+slider.value)">
      {{ 'quiz-volgende-knop-tekst' | fromDictionary }}
    </button>
  </footer>
</div>
