import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromQuestions from '../reducers/questions.reducer';
import { Question } from '../../models';
import * as fromRoot from '../../../store';

export const getQuestionsState = createSelector(fromFeature.getQuizState, (state: fromFeature.QuizState) => state.questions);

export const getQuestionEntities = createSelector(getQuestionsState, fromQuestions.getQuestionsEntities);
export const getQuestionsLoaded = createSelector(getQuestionsState, fromQuestions.getQuestionsLoaded);
export const getQuestionsLoading = createSelector(getQuestionsState, fromQuestions.getQuestionsLoading);

export const getAllQuestions = createSelector(getQuestionEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getAllOrderedQuestions = createSelector(getAllQuestions, questions => {
  return questions.sort((a, b) => (a >= b ? 1 : -1)).map((a, i) => ({ ...a, order: i + 1 }));
});
