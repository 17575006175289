import { ChangeDetectionStrategy, Component, Input, } from '@angular/core';

@Component({
  selector: 'app-emoji-animation',
  templateUrl: './emoji-animation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiAnimationComponent {
  @Input() value!: number;
}
