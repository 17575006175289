<app-header class="quiz">
  <div class="container text-max-width-xs text-center">
    <ul class="quiz-list mt-4" [style.--quiz-items-total]="questions$().length">
      <li
        class="quiz-list__item card theme-primary card--quiz"
        *ngFor="let item of questionsWithAnswers$(); trackBy: trackByFn; let i = index"
        [style.--quiz-item-index]="i"
        [style.--quiz-item-distance]="getDistanceFromIndex(i)"
        [attr.data-distance]="getDistanceFromIndex(i)"
        [class.is--done]="i < activeQuizIndex"
        [class.d-none]="getDistanceFromIndex(i) > 3"
        [ngClass]="'from-top--' + getDistanceFromIndex(i)"
      >
        <article class="quiz__question">
          <header>
            <span class="text-tertiary fw-bold d-inline-block w-100 mb-2">
              {{ 'quiz-vraag-stap' | fromDictionary }} {{ item.question.order }}
            </span>
            <h2 class="h4 font-body">{{ item.question.text }}</h2>
          </header>

          <app-quiz-slider
            class="d-block mt-4"
            [value]="item.answer"
            (updatedValue)="saveAnwser($event, item.question.id)"
          ></app-quiz-slider>
        </article>
      </li>
      <li class="quiz-list__item-loader" *ngIf="questions$().length === activeQuizIndex">
        <svg class="circle-loader mb-4" viewBox="25 25 50 50">
          <circle class="circle-loader__bg" cx="50" cy="50" r="20" fill="none" stroke="white" stroke-width="2" />
          <circle class="circle-loader__path" cx="50" cy="50" r="20" fill="none" stroke="var(--bs-primary)" stroke-width="2" />
        </svg>
        <br />
        <span class="h5 text-primary">{{ 'quiz-antwoorden-verwerken' | fromDictionary }}</span>
      </li>
    </ul>
    <button
      class="h5 link mt-5 d-block w-100"
      *ngIf="activeQuizIndex !== 0 && !(questions$().length === activeQuizIndex)"
      (click)="prevQuestion()"
    >
      {{ 'quiz-vorige-knop-tekst' | fromDictionary }}
    </button>
    <a class="h5 link mt-5 d-block w-100" *ngIf="activeQuizIndex === 0" [routerLink]="['/']">
      {{ 'quiz-vorige-naar-home-knop-tekst' | fromDictionary }}
    </a>
  </div>
</app-header>
